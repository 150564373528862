@media only screen and (max-width: 480px) {
    #usersTable {
        font-size: 11px;
        overflow-wrap: break-word;
        text-align: center;
        color: #fff;
    }

    #usersIcon {
        display: inline-block;
    }
}

@media only screen and (min-width: 480px) {
    #usersTable {
        font-size: 14px;
        font-family: 'Raleway', sans-serif;
        color: #fff;
        overflow-wrap: break-word;
        text-align: center;
    }

    #usersIcon {
        display: flex;
    }
}

