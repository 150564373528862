html {
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0px;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to right, #3a5f72, #16222a);
}

code {
  font-family: 'Raleway',
    monospace;
}
