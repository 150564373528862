.waveBorder {
    position: relative;
    height: 100px;
    width: 100%;
    background: linear-gradient(to right, #3a5f72, #16222a);
  }
  
.waveBorder::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 100%;
    width: 60%;
    height: 100px;
    border-bottom: 3px solid;
    border-top: none;
    border-color: #71d8ce;
    background: linear-gradient(to right, #2c4755, #16222a);
    right: 0px;
    top: 50px;
  }


  .waveBorder::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 100%;
    width: 40%;
    height: 100px;
    border-top: 3px solid;
    border-bottom: none;
    border-color: #71d8ce;
    background-color: #2C5364;
    left: 0px;
    top: 50px;   
}

.tabs:hover {
  background-color: transparent;
  font-weight: bold;
}

  @media screen and (max-width: 600px) {
    .waveBorder::after { 
      height: 70px;
    }

    .waveBorder::before { 
      height: 70px;
    }
    .waveBorder { 
      height: 90px;
    }
  }

  .privacyPolicyStrong {
    font-size: 1.4rem;
    
  }

  .privacyPolicyDiv {
    margin-bottom: 1.2rem;
  }

  .aTags {
    color: white;
    padding: 0;
    font-size: 1.2rem;
    font-weight: bold
  }
